angular.module("app").service('_taskService', function ($rootScope, _v, _calendar, _view, _i18n, _logicService) {

    this.DATETIME_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSS";

    this.SORTED_ACTIVITIES = ['NOTE', 'PA_INSECT', 'INSECTS', 'PA_DISEASE', 'DISEASES', 'WEEDS', 'STAND', 'FENOLOGY'];

    var t = this;


    this.groupResults = function(task) {

        task.points.forEach(scoutPoint => {
            scoutPoint.activities = [];

            if (_.size(task.paInsectSamplings) > 0) {
                scoutPoint.activities.push({
                    type: {
                        rawName: "PA_INSECT",
                        name: _i18n.getString("task.activity.PA_INSECT")
                    },
                    results: [],
                    unclassified: []
                });
            }
            if (_.size(task.paDiseaseSamplings) > 0) {
                scoutPoint.activities.push({
                    type: {
                        rawName: "PA_DISEASE",
                        name: _i18n.getString("task.activity.PA_DISEASE")
                    },
                    results: [],
                    unclassified: []
                });
            }
            _.each(scoutPoint.requiredActivities, function (e) {
                scoutPoint.activities.push({
                    type: {
                        rawName: e,
                        name: _i18n.getString("task.activity." + e)
                    },
                    results: [],
                    unclassified: []
                });
            });
            _.each(scoutPoint.optionalActivities, function (e) {
                scoutPoint.activities.push({
                    type: {
                        rawName: e,
                        name: _i18n.getString("task.activity." + e)
                    },
                    free: true,
                    results: [],
                    unclassified: []
                });
            });

            scoutPoint.results.forEach(result => {
                var type = result.type;

                var activity = _.find(scoutPoint.activities, function (e) {
                    return e.type.rawName.toUpperCase() == type.toUpperCase()
                });
                if (!activity) {
                    activity = {
                        type: {
                            rawName: type.toUpperCase(),
                            name: _i18n.getString("task.activity." + type)
                        },
                        results: [],
                        unclassified: []
                    };
                    scoutPoint.activities.push(activity);
                }
                var typeName = activity.type.rawName;

                if (_.contains(scoutPoint.optionalActivities, activity.type.rawName)) {
                    activity.free = true;
                }

                var resultJson = angular.fromJson(result.resultJson);

                if (typeName== 'PA_INSECT') {
                    var insect = _.find(activity.results, function (r) { return r.id == resultJson.insect.id; });
                    if (!insect) {
                        insect = {id: resultJson.insect.id, name: _i18n.getStringFromBundleLat(resultJson.insect.name), stages: []};
                        activity.results.push(insect);
                    }
                    var stage = _.find(insect.stages, function (s) { return s.id == resultJson.stage.id; });
                    if (!stage) {
                        stage = {id: resultJson.stage.id, name: _i18n.getStringFromBundleEn(resultJson.stage.name), results: [], stageOrder: resultJson.stage.stageOrder};
                        insect.stages.push(stage);
                    }
                    stage.results.push({
                        resultJson: resultJson,
                        mediaList: result.mediaList,
                        type: result.type,
                        id: result.id
                    });
                }
                if (typeName == 'INSECTS') {
                    //{"insect":{"id":-1,"name":"en:No pests,ru:Нет вредителей,pt:Sem pragas","maxDetected":-1,"stages":[]},"stage":{"id":-1,"name":"","stageOrder":-1},"count":0}
                    if (!_.isUndefined(resultJson.insect)) { //old results
                        if ((resultJson.insect.id > 0 && resultJson.stage.id > 0) || (resultJson.insect.id == -1 && resultJson.stage.id == -1 && resultJson.count == 0)) {
                            var insect = _.find(activity.results, function (r) {
                                return r.id == resultJson.insect.id;
                            });
                            if (!insect) {
                                insect = {
                                    id: resultJson.insect.id,
                                    name: _i18n.getStringFromBundleLat(resultJson.insect.name),
                                    stages: []
                                };
                                activity.results.push(insect);
                            }
                            var stage = _.find(insect.stages, function (s) {
                                return s.id == resultJson.stage.id;
                            });
                            if (!stage) {
                                stage = {
                                    id: resultJson.stage.id,
                                    name: _i18n.getStringFromBundleEn(resultJson.stage.name),
                                    results: [],
                                    stageOrder: resultJson.stage.stageOrder
                                };
                                insect.stages.push(stage);
                            }
                            stage.results.push({
                                resultJson: resultJson,
                                media: result.media,
                                type: result.type,
                                id: result.id
                            });
                        } else {
                            activity.unclassified.push({
                                resultJson: resultJson,
                                media: result.media,
                                type: result.type,
                                id: result.id
                            });
                        }
                    }
                }
                if (typeName== 'PA_DISEASE') {
                    var disease = _.find(activity.results, function (r) { return r.id == resultJson.disease.id; });
                    if (!disease) {
                        disease = {id: resultJson.disease.id, name: _i18n.getStringFromBundleLat(resultJson.disease.name), results: []};
                        activity.results.push(disease);
                    }
                    disease.results.push({
                        resultJson: resultJson,
                        mediaList: result.mediaList,
                        type: result.type,
                        id: result.id
                    });
                }
                if (typeName == 'DISEASES') {
                    if (!_.isUndefined(resultJson.disease)) { //old results
                        if (resultJson.disease.id > 0 || (resultJson.disease.id == -1 && resultJson.severity == 0)) {
                            var disease = _.find(activity.results, function (r) {
                                return r.id == resultJson.disease.id;
                            });
                            if (!disease) {
                                disease = {
                                    id: resultJson.disease.id,
                                    name: _i18n.getStringFromBundleLat(resultJson.disease.name),
                                    results: []
                                };
                                activity.results.push(disease);
                            }
                            disease.results.push({
                                resultJson: resultJson,
                                media: result.media,
                                type: result.type,
                                id: result.id
                            });
                        } else {
                            activity.unclassified.push({
                                resultJson: resultJson,
                                media: result.media,
                                type: result.type,
                                id: result.id
                            });
                        }
                    }
                }
                if (typeName == 'WEEDS') {
                    if (!_.isUndefined(resultJson.weed)) { //old results
                        if ((resultJson.weed.id > 0 && resultJson.stage.id > 0) || (resultJson.weed.id == -1 && resultJson.stage.id == -1 && resultJson.count == 0)) {
                            var weed = _.find(activity.results, function (r) {
                                return r.id == resultJson.weed.id;
                            });
                            if (!weed) {
                                weed = {
                                    id: resultJson.weed.id,
                                    name: _i18n.getStringFromBundleLat(resultJson.weed.name),
                                    stages: []
                                };
                                activity.results.push(weed);
                            }
                            var stage = _.find(weed.stages, function (s) {
                                return s.id == resultJson.stage.id;
                            });
                            if (!stage) {
                                stage = {
                                    id: resultJson.stage.id,
                                    name: _i18n.getStringFromBundleEn(resultJson.stage.name),
                                    results: [],
                                    stageOrder: resultJson.stage.stageOrder
                                };
                                weed.stages.push(stage);
                            }
                            stage.results.push({
                                resultJson: resultJson,
                                media: result.media,
                                type: result.type,
                                id: result.id
                            });
                        } else {
                            activity.unclassified.push({
                                resultJson: resultJson,
                                media: result.media,
                                type: result.type,
                                id: result.id
                            });
                        }
                    }
                }
                if (typeName == 'STAND' || typeName == 'FENOLOGY' || typeName == "NOTE") {
                    activity.results.push({
                        resultJson: resultJson,
                        media: result.media,
                        type: result.type,
                        id: result.id
                    });
                }
            });

            scoutPoint.activities = _.sortBy(scoutPoint.activities, function(a) {
                return _.indexOf(t.SORTED_ACTIVITIES, a.type.rawName.toUpperCase());
            });

            scoutPoint.hasFree = false;
            scoutPoint.hasRequired = false;

            _.each(scoutPoint.activities, function (a) {
                if (a.free) {
                    scoutPoint.hasFree = true;
                } else {
                    scoutPoint.hasRequired = true;
                }

                if (a.type.rawName == 'PA_INSECT' || a.type.rawName == 'INSECTS'|| a.type.rawName == 'WEEDS') {
                    a.results = _.sortBy(a.results, function (r) { return r.name; });
                    _.each(a.results, function (r) {
                        r.stages = _.sortBy(r.stages, function (r) { return r.stageOrder; });
                        _.each(r.stages, function (s) {
                            s.results = _.sortBy(s.results, function (r) { return r.id; });
                        })
                    });
                    a.unclassified = _.sortBy(a.unclassified, function (r) { return r.id; });
                }
                if (a.type.rawName == 'PA_DISEASE' || a.type.rawName == 'DISEASES') {
                    a.results = _.sortBy(a.results, function (r) { return r.name; });
                    _.each(a.results, function (r) {
                        r.results = _.sortBy(r.results, function (rr) { return rr.id; });
                    });
                    a.unclassified = _.sortBy(a.unclassified, function (r) { return r.id; });
                }
                if (a.type.rawName == 'STAND' || a.type.rawName == 'FENOLOGY') {
                    a.results = _.sortBy(a.results, function (r) { return r.id; });
                    a.unclassified = _.sortBy(a.unclassified, function (r) { return r.id; });
                }
            });
        });
    };

    this.insectsHasNotes = function (insects) {
        return _.size(_.filter(insects, function (e) { return e.task == false || (e.task == true && e.note == true); })) > 0;
    };


    this.buildTaskInsects = function (insectsDict, taskInsects) {
        var result = [];
        _.each(taskInsects, function (e) {
            _.each(insectsDict, function (de) {
                if ((e.insect.id == de.id)) {
                    result.push({
                        id: e.insect.id,
                        formName: _i18n.getStringFromBundleLat(de.name),
                        note: e.note,
                        task: e.task,
                        maxThreshold: de.maxThreshold,
                        maxThresholdPlants: de.maxThresholdPlants
                    });
                }
            });
        });
        result = _.sortBy(result, function (e) { return e.formName });
        result = _.sortBy(result, function (e) { return !e.task });

        return result;
    };

    this.buildPaDiseaseSamplingsGroup = function(task, diseasesDict) {
        var part1 = [];
        var part2 = [];
        _.each(task.paDiseaseSamplings, function (d) {
            _.each(diseasesDict, function (dd) {
                if (d.diseaseId == dd.id) {
                    part1.push({
                        id: d.diseaseId,
                        formName: _i18n.getStringFromBundleLat(dd.name),
                        plantsCount: d.plantsCount,
                        onField: false
                    });
                }
            });
        });
        part1 = _.sortBy(part1, function (e) { return e.formName; });

        _.each(task.points, function (point) {
            _.each(point.results, function (result) {
                if (result.type=='PA_DISEASE') {
                    var r = angular.fromJson(result.resultJson);
                    var d = _.find(part1, function (e) { return e.id == r.disease.id; });
                    if (!d) {
                        var d = _.find(part2, function (e) { return e.id == r.disease.id; });
                        if (!d) {
                            _.each(diseasesDict, function (dd) {
                                if (r.disease.id == dd.id) {
                                    part2.push({
                                        id: r.disease.id,
                                        formName: _i18n.getStringFromBundleLat(dd.name),
                                        plantsCount: r.samplingObject.plantsCount,
                                        onField: true
                                    });
                                }
                            });
                        }
                    }
                }
            });
        });
        part2 = _.sortBy(part2, function (e) { return e.formName; });


        return part1.concat(part2);
    };

    this.buildPAInsectSamplingsGroup = function (task, insectsDict) {
        var part1 = [];
        _.each(task.paInsectSamplings, function (i) {
            var dictInsect = _.find(insectsDict, function (ii) {return i.insectId == ii.id});
            var iii = _.find(part1, function (e) { return e.id == i.insectId; });
            if (!iii) {
                iii = {};
                iii.id = i.insectId;
                iii.formName = _i18n.getStringFromBundleLat(dictInsect.name);
                iii.plantsCount = i.plantsCount;
                iii.stages = [];
                iii.onField = false;
                part1.push(iii);
            }
            _.each(i.stages, function (stageId) {
                var dictStage = _.find(dictInsect.stages, function (s) { return s.id == stageId; });
                iii.stages.push({formName: _i18n.getStringFromBundleEn(dictStage.name), id: dictStage.id, stageOrder: dictStage.stageOrder});
            });
        });

        part1 = _.sortBy(part1, function (e) { return e.formName; });
        var part2 = [];
        _.each(task.points, function (point) {
            _.each(point.results, function (result) {
                if (result.type=='PA_INSECT') {
                    var r = angular.fromJson(result.resultJson);
                    var iii = _.find(part1, function (e) { return e.id == r.insect.id; });
                    if (!iii) {
                        var dictInsect = _.find(insectsDict, function (e) {return r.insect.id == e.id});
                        var iii = _.find(part2, function (e) { return e.id == r.insect.id; });

                        if (!iii) {
                            iii = {};
                            iii.id = r.insect.id;
                            iii.formName = _i18n.getStringFromBundleLat(dictInsect.name);
                            iii.plantsCount = r.samplingObject.plantsCount;
                            iii.stages = [];
                            iii.onField = true;
                            part2.push(iii);
                        }
                        var dictStage = _.find(dictInsect.stages, function (s) {
                            return s.id == r.stage.id;
                        });

                        var stage = _.find(iii.stages, function (s) {
                            return s.id == dictStage.id;
                        });
                        if (!stage) {
                            iii.stages.push({
                                formName: _i18n.getStringFromBundleEn(dictStage.name),
                                id: dictStage.id,
                                stageOrder: dictStage.stageOrder
                            });
                        }
                    }
                }
            });
        });
        part2 = _.sortBy(part2, function (e) { return e.formName; });

        var xResult = part1.concat(part2);

        _.each(xResult, function (e) {
            e.stages = _.sortBy(e.stages, function (e) { return e.stageOrder; });
            e.stagesStr = _.pluck(e.stages, 'formName').join(", ");
        });

        return xResult;
    };

    this.buildActivitiesStat = function (task) {
        var xResult = {};
        task.points.forEach(scoutPoint => {
            _.each(scoutPoint.requiredActivities, function (name) {
                if (_.isUndefined(xResult[name])) {
                    xResult[name] = {count: 1, free: false, formName: _i18n.getString("task.activity." + name)};
                } else {
                    xResult[name].count++;
                }
            });
            _.each(scoutPoint.optionalActivities, function (name) {
                if (_.isUndefined(xResult[name])) {
                    xResult[name] = {count: 1, free: true, formName: _i18n.getString("task.activity." + name)};
                } else {
                    xResult[name].count++;
                }
            });
        });
        _.each(xResult, function (value, key) {
            value.percent = _logicService.round(100 * value.count / _.size(task.points));
        });

        return xResult;
    };

    this.getPointsWithInsects = function(task, insectIds) {
        var resultz = new Set();

        task.points.forEach(scoutPoint => {

            scoutPoint.results.forEach(result => {
                if (result.type == "PA_INSECT" || result.type == "INSECTS" ) {
                    var r = angular.fromJson(result.resultJson);
                    if (!_.isUndefined(r.insect)) { //old results
                        _.each(insectIds, function (e) {
                            if (e == r.insect.id) {
                                resultz.add(scoutPoint.pointIndex);
                            }
                        });
                    }
                }
            });
        });

        return resultz;
    };
});